import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as MatButton } from '@material-ui/core';
import React, { ReactNode } from 'react';
import './style.scss';

const Button = (props: 
    {
        className?: string,
        children?: ReactNode, 
        leftIcon?: IconProp, 
        rightIcon?: IconProp, 
        style?: React.CSSProperties,
        onClick?: React.MouseEventHandler<HTMLButtonElement>
        type?: "submit" | "button",
        rightBlock?: boolean,
    }) => {
        const bt = <MatButton type={props.type ?? "submit"} className={"Button " + (props.className ?? "")} style={props.style} onClick={props.onClick}>
            {props.leftIcon !== undefined ? <FontAwesomeIcon icon={props.leftIcon} /> : ""} 
            <span style={{padding: "0 10px"}}>{props.children}</span>
            {props.rightIcon !== undefined ? <FontAwesomeIcon icon={props.rightIcon} /> : ""}
        </MatButton>

        if (props.rightBlock) {
            return <div style={{textAlign: "right"}}>{bt}</div>
        }else {
            return bt;
        }
    }
        

export default Button