import { FormControlLabel, Checkbox as MuiCheckbox, FormControl  } from "@material-ui/core";
import React, { ReactNode, useState } from "react";

const Checkbox = (props: {children: ReactNode, validate?: boolean, className?: string, onChange?: (value: boolean) => void}) => 
    {
        const [checked, setChecked] = useState(false);

        return <FormControl className={props.className}>
            <FormControlLabel className={"MyCheckbox " + (props.validate && !checked ? ' error' : '')}
                    control={<MuiCheckbox name="gilad" onChange={(x: React.ChangeEvent<HTMLInputElement>) => 
                        {setChecked(x.target.checked); props.onChange?.(x.target.checked)}}/>}
                    label={<div style={{fontSize: "small", textAlign: "justify"}}>{props.children}</div>}
                />
        </FormControl>
    }

export default Checkbox;