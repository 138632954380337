import Fuse from 'fuse.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { MenuItem, Paper } from '@material-ui/core'
import { useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Suggestion } from '../utils/data';
import { isNotUndefined } from '../utils/utils';
import './AutocompletedInput.scss';

export interface SuggestionsProvider<T extends Suggestion> {
    suggest: (query: string, limit?: number) => Promise<T[]>,
    lookup: (id: string) => Promise<T>
}

export class SimpleSuggestionsProvider<T extends Suggestion> implements SuggestionsProvider<T> {
    constructor (suggestions_gatherer: Promise<T[]>){
        suggestions_gatherer.then(suggestions => {
            const suggestionMap: {[id: string]: T} = {};
            for (const s of suggestions) {
                suggestionMap[s.id] = s;
            }
            this.suggestions.next(suggestionMap);
            this.fuse.next(new Fuse(suggestions, {keys: ["label"]}));
        })
    }

    private fuse = new BehaviorSubject<Fuse<T> | undefined>(undefined);
    private suggestions = new BehaviorSubject<{[id: string]: T} | undefined>(undefined);
    
    get isLoadding(){ 
        return this.fuse.value === undefined;
    }
    
    async suggest(query: string, limit: number = 5): Promise<T[]> { 
        const fuse = await this.fuse.pipe(
            first(isNotUndefined)
        ).toPromise()

        return fuse.search(query, {limit: limit}).map((r) => r.item)
    }

    async lookup(id: string) {
        const suggestionsMap = await this.suggestions.pipe(
            first(isNotUndefined)
        ).toPromise()
        
        return suggestionsMap[id];
    };
}


type Props<T extends Suggestion> = {
    id?: string,
    placeholder: string,
    faIcon: IconDefinition, 
    baseZIndex: number,
    style?: React.CSSProperties,
    onSelect?: (suggestion: T, isUserSelected: boolean) => void,
    suggestionProvider?: SuggestionsProvider<T>,
    suggestionPresenter: (suggestion: T, isSelected: boolean, isHighlighted: boolean) => JSX.Element,
    initialValueId?: string,
    addNew?: {
        handler: () => void,
        menuItemChild: JSX.Element,
    }
};


export function AutocompletedInput<T extends Suggestion>( props: Props<T>){
    const [isActive, setIsActive] = useState(false);
    const [highlightedItem, setHighlightItem] = useState<number | undefined>();
    const [userPrompt, setUserPrompt] = useState("");

    const [suggestions, setSuggestions] = useState<T[] | undefined>(undefined);
    const [selectedItem, setSelectItem] = useState<number | undefined>(undefined);

    const [initialValueId, setInitialValueId] = useState<string | undefined>(undefined);
    
    const selectItem = (item: number) => {
        if (suggestionsDOM !== undefined && suggestionsDOM?.length - 1 === item && props.addNew !== undefined) {
            props.addNew.handler();
        } else {
            setSelectItem(item);
            setIsActive(false);
            setHighlightItem(0);
            if (props.onSelect !== undefined && suggestions !== undefined) {
                props.onSelect(suggestions[item], true)
            }
        }
    }

    const updateSuggestions = async (q: string) => {
        if (props.suggestionProvider !== undefined) {
            const newSuggestions = await props.suggestionProvider?.suggest(q);
        
            setSuggestions(newSuggestions);
            setHighlightItem(newSuggestions.length > 0 ? 0 : undefined);    

            return newSuggestions;
        }
        else {
            return [];
        }
    }

    if (props.initialValueId !== initialValueId 
        // && (suggestions === undefined || selectedItem === undefined || suggestions[selectedItem].id !== props.initialValueId) /* don't trigger when is already selected - avoid removing original prompt */
    ){
        setInitialValueId(props.initialValueId);
        if (props.initialValueId !== undefined) {
            props.suggestionProvider?.lookup(props.initialValueId).then((initialValue) => {
                if (true || userPrompt !== initialValue.label) {
                    setUserPrompt(initialValue.label);
                    updateSuggestions(initialValue.label).then((newSuggestions) => {
                        setSelectItem(newSuggestions?.findIndex((s) => s.id === initialValue.id)); 
                    })
                    
                    if (props.onSelect !== undefined) {
                        props.onSelect(initialValue, false);
                    }
                }
            });    
        } else {
            setUserPrompt("");
            updateSuggestions("");
        }
        
    }

    const suggestionsDOM = suggestions?.map((suggestion, idx) => (
        <MenuItem
            style={{ display: "block", padding: 0 }}
            key={`suggestion-${idx}`}
            onClick={() => selectItem(idx)}
            onMouseEnter={() => setHighlightItem(idx)}
            selected={highlightedItem === idx}
        >
            {props.suggestionPresenter(suggestion, selectedItem === idx, highlightedItem === idx)}
        </MenuItem>
    ));

    if (suggestionsDOM !== undefined && (suggestionsDOM.length > 0 || userPrompt !== '') && props.addNew !== undefined) {
        const addNewIdx = suggestionsDOM.length;
        suggestionsDOM?.push(
            <MenuItem
                style={{ display: "block", padding: 0 }}
                key={`suggestion-add-new`}
                onClick={() => selectItem(addNewIdx)}
                onMouseEnter={() => setHighlightItem(addNewIdx)}
                selected={highlightedItem === addNewIdx}
        >
            {props.addNew.menuItemChild}
        </MenuItem>
        )
    }

    return <div className={`AutocompletedInput ${isActive && suggestions !== undefined ? 'active' : ''}`}
            style={props.style}

            // https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
            onBlur={(e) => { 
                const currentTarget = e.currentTarget;

                // Check the newly focused element in the next tick of the event loop
                setTimeout(() => {
                // Check if the new activeElement is a child of the original container
                if (!currentTarget.contains(document.activeElement)) {
                    setIsActive(false); setHighlightItem(0);    
                }
                }, 0);
                
            }}>
        <div className="input-group" style={{zIndex: props.baseZIndex * 2 + 1}}>
            <div className="input-group-prepend">
                <div className="input-group-text"><FontAwesomeIcon icon={props.faIcon} /></div>
            </div>
            <input 
                id={props.id}
                type="text" 
                className="form-control" 
                value={selectedItem !== undefined && !isActive && suggestions !== undefined && suggestions[selectedItem] !== undefined ? suggestions[selectedItem].label : userPrompt}
                placeholder={props.placeholder}
                onChange={((e) => {
                    setUserPrompt(e.target.value);
                    updateSuggestions(e.target.value);
                })}
                onFocus={(e) => { setIsActive(true); updateSuggestions(e.target.value); e.target.select(); }}
                onKeyDown={(e) => {

                    if (suggestionsDOM === undefined)
                        return;

                    const UP = 38;
                    const DOWN = 40;
                    const ENTER = 13;
                    
                    if (e.keyCode === DOWN) {
                        e.preventDefault();
                        const nextIdx = highlightedItem !== undefined ? highlightedItem + 1 : 0;
                  
                        if (nextIdx < suggestionsDOM.length) {
                            setHighlightItem(nextIdx);
                        } else {
                            setHighlightItem(0);
                        }
                      }

                      if (e.keyCode === UP) {
                        e.preventDefault();
                        const nextIdx = highlightedItem !== undefined ? highlightedItem - 1 : suggestionsDOM.length - 1;
                  
                        if (nextIdx >= 0) {
                            setHighlightItem(nextIdx);
                        } else {
                            setHighlightItem(suggestionsDOM.length - 1);
                        }
                      }

                      if (e.keyCode === ENTER) {
                          e.preventDefault();
                          if (highlightedItem !== undefined){
                            selectItem(highlightedItem);
                            e.currentTarget.blur()
                          }
                      }
                }}
            ></input>
        </div>
        <Paper className="SuggestionBox"
            style={{zIndex: props.baseZIndex * 2}}
        >
            {suggestionsDOM}
        </Paper>
        </div>
    
}  