import firebase from 'firebase';
import Papa from 'papaparse';
import { FirebaseRequest } from '../../../shared/data-model/requests';

// TODO coalesce multiple requests to the same path
export const getFileFromFirebase = async (path: string) => {
    console.log("Loading", path);
    return new Promise<string>(async (resolve, reject) => {
        const storage = firebase.storage()
        const fbFileUrl = await storage.ref(path).getDownloadURL()
        const xhr = new XMLHttpRequest();
    
        xhr.responseType = 'blob';
        xhr.onload = async (event) => {
            console.log(`loaded ${path}`)
            if (xhr.status === 200){
                const blob: Blob = xhr.response;
                const txt = await blob.text();
                resolve(txt);
            } else {
                reject();
            }
        };
        xhr.onerror = reject;
        xhr.open('GET', fbFileUrl);
        xhr.send();
    })   
}

// const putObjectToFirebaseStorage = async (path: string, object: unknown) => {
//     const storage = firebase.storage()
//     await storage.ref(path).putString(JSON.stringify(object));
// }

export const uploadRequest = async (id: string, object: FirebaseRequest) => {
    await firebase.firestore().collection('requests').doc(id).set(object);
}


const cache: {[key: string]: any} = {};

// TODO coalesce multiple requests to the same path
export const parseCsvFromFirebase = async <T extends { [key in keyof T]: unknown }>(path: string, transformers?: { [key in keyof T]?: (value: string) => unknown }): Promise<T[]> => {
    
    const cacheKey = `parseCsvFromFirebase:${path}`;
    if (cache[cacheKey] !== undefined) {
        return cache[cacheKey] as T[];
    } else {
        const result = await new Promise<T[]>(async (resolve) => {
            const storage = firebase.storage()
            console.log("Loading", path);
            const fbFileUrl = await storage.ref(path).getDownloadURL()
            Papa.parse(fbFileUrl, {
                download: true,
                header: true,
                complete: (results) =>
                    resolve(results.data.map((_row) => {
                        let processed_row: any = {};
                        const row = _row as { [key in keyof T]: string }
                        let k: keyof T;
                        for (k in row) {
                            processed_row[k] = transformers?.[k]?.(row[k]) ?? row[k];
                        }
                        return processed_row as T;
                    }))
            })
        });

        cache[cacheKey] = result;
        return result;
    }
}

