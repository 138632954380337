import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InfoAgenzia = (props: {
    name: string,
    address: string,
    subheader?: JSX.Element,
    // number: string,
    // web: string,
}) => {

    const agInfo: Array<[IconProp, JSX.Element]> = [
        [faMapPin, <span>{props.address}</span>],
        // [faPhone, <span>{props.number}</span>],
        // [faLaptop, <a href={(props.web.slice(0, 4) != 'http' ? 'https://' : '')+props.web} target="_blank">{props.web}</a>]
    ];
            
    return <div className="InfoAgenzia">
            <div className="hide-on-lg"><h2>{props.name}</h2></div>
            {props.subheader}
            <iframe  
            // onLoad TODO show onloading
            title="map"
            width="100%" 
            height="350" 
            src={"https://maps.google.it/maps?q="+encodeURI(props.address)+"&t=&z=15&ie=UTF8&iwloc=&output=embed&language=ita"} 
            frameBorder="0" scrolling="no" 
            />
            {agInfo.map(([icon, txt], idx) => 
            <div key={idx} style={{marginTop: ".3em"}}>
                <FontAwesomeIcon icon={icon} style={{width: "2em"}}/>
                {txt}
            </div>)}
        </div>
    }

export default InfoAgenzia;