import { faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from '@material-ui/core';
import { useReducer, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../utils/auth/use-auth';
import { useUser } from '../../utils/use-user';
import Button from '../Button';
import InfoBox from '../InfoBox';
import Input, { InputValue } from '../Input';
import Select from '../Select';
import './style.scss';
import { UserPersona } from "../../../../shared/data-model/user";
import { trackOnboarding } from '../../utils/data/tracking';
import { TEXT } from '../../utils/texts';

const OnBoarding = () => {
    const history = useHistory();
    const location = useLocation();
    const {user, updateUserInfo} = useUser();
    const auth = useAuth();

    // const [validate, setValidate] = useState(false);

    type Answers = {
        userPersona?: string;
        otherPersona?: InputValue;
        contatti?: string;
    };

    type State = {
        answers: Answers;
        errors: {[key in keyof Answers]?: string;},
        submitted: boolean,
        submitState?: "ongoing" | "done"; 
    };
    
    const [displayName, setDisplayName] = useState(user?.displayName);

    const reducer = (state: State, action: {patch?: Answers}): State => {
        if (action.patch === undefined){
            state.submitted = true; // TODO
        } else {
            const patch = action.patch;
            let updated = { ...state.answers};
            Object.entries(patch).forEach(
                ([key, value]) => {
                    if (value !== undefined){
                        updated[key as keyof Answers] = value as any;
                    }
                }
              );
            state.answers = updated;
        }

        if (state.submitted) {
            state.errors.userPersona = state.answers.userPersona === undefined ? "Seleziona un opzione" : undefined;
            if (state.errors.userPersona === 'altro'){
                state.errors.otherPersona = state.answers.otherPersona?.error;
            } else {
                state.errors.otherPersona = undefined;
            }
            
            if (['compratore', 'locatore', 'venditore', 'locatario'].includes(state.answers.userPersona ?? '') && state.answers.contatti === undefined){
                state.errors.contatti = 'Seleziona un opzione';
            }else{
                state.errors.contatti = undefined;
            }

            if (
                Object.values(state.errors).every(x => x === undefined) 
                && action.patch === undefined
                && state.submitState === undefined
                ){
                    state.submitState = "ongoing";
                    if (user !== undefined) {
                        if (user.displayNameToConfirm) {
                            user.displayNameToConfirm = false;
                            user.displayName = displayName === undefined || displayName.length < 5 ? user.displayName : displayName;
                            auth.user?.updateProfile({displayName: displayName});
                        }
                        user.onboardingAnswers = {
                            userPersona: state.answers.userPersona as UserPersona,  // WARNING not really type safe
                            otherPersona: state.answers.otherPersona?.value,
                            contatti: state.answers.contatti,
                        }
                        trackOnboarding({
                            userPersona: state.answers.userPersona as string,
                            otherPersona: state.answers.otherPersona?.value,
                            contatti: state.answers.contatti,
                        });
                        updateUserInfo(user).then(() => {
                            if (location.state?.pendingRedirect !== undefined) {
                                history.replace(location.state.pendingRedirect);
                            } else {
                                history.replace('/')
                            }
                        })
                    }
                    
            }
        }
        
        return {...state};
    }

    
    const [state, update] = useReducer(reducer, {
        answers: {},
        errors: {},
        submitted: false
    } as State)

    if (user === undefined){
        return null; // just wait for user info to come (auth problem are handled elsewhere)
    }
    
    

    return <InfoBox title="Registrati" className="OnboardingBox">
       
                        <Input placeholder={user.displayName}
                            value={user.displayName}
                            name="displayName" style={{display: user.displayNameToConfirm ? 'inherit' : 'none'}}
                            icon={faUser}
                            onChange={(x) => setDisplayName(x.value)}
                            validate={state.submitted} 
                            validator={
                                (v) => v.length < 3 && v.length > 0 ? "Inserisci almeno 3 caratteri" : undefined
                            }
                            footSuggestion={TEXT.suggestionNomeUtente}
                        /> 
                        <Select label="Quale descrizione ti rappresenta meglio?"
                            onChange={(x) => update({patch:{userPersona: x}})}
                            error={state.submitted && state.errors.userPersona !== undefined}
                            >
                            <MenuItem value="compratore">Compratore</MenuItem>
                            <MenuItem value="venditore">Venditore</MenuItem>
                            <MenuItem value="locatore">Locatore (Ho un immobile da affittare)</MenuItem>
                            <MenuItem value="locatario">Affittuario (Cerco un immobile in affitto)</MenuItem>
                            <MenuItem value="agenzia">Agenzia Immobiliare</MenuItem>
                            <MenuItem value="altro">Altro</MenuItem>
                        </Select>
                        <Input placeholder="Descriviti meglio..." name="1-altro" style={{display: state.answers.userPersona === 'altro' ? 'inherit' : 'none'}}
                            onChange={(x) => update({patch: {otherPersona: x}})}
                            validate={state.submitted} 
                            validator={
                                (v) => v.length < 4 ? "Descriviti meglio" : undefined
                            }
                        />
                    
                        <Select label="Con quante agenzie sei entrato in contatto?" 
                            style={{display: ['compratore', 'locatore', 'venditore', 'locatario'].includes(state.answers.userPersona ?? '') ? 'inherit' : 'none'}}
                            onChange={(x) => update({patch:{contatti: x}})}
                            error={state.submitted && state.errors.contatti !== undefined}
                            >
                            <MenuItem value="nessuna">Non sono entrato in contatto con alcuna</MenuItem>
                            <MenuItem value="una">Sono entrato in contatto con una</MenuItem>
                            <MenuItem value="più">Sono entrato in contatto con più di una</MenuItem>
                        </Select>

                    <br />
                    <Button rightBlock rightIcon={faChevronRight} onClick={() => {update({});}}>Continua</Button>
            </InfoBox>    
}

export default OnBoarding