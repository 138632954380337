import React, { ReactNode, useContext, useReducer } from "react";
import { useState } from "react";
import Input from "./Input";


export const smartForm = <Model extends { [index: string]: string }>(onSubmit: (data: Model) => void) => {
    type Key = keyof Model & string;

    interface FormContextType{
        toValidate: boolean,
        data: { [key in Key]?: string; },   // TODO what about optionals in Model ?
        errors: { [key in Key]?: string; },
        update: (key: Key, value: string, error?: string) => void
    };

    const FormContext = React.createContext<FormContextType>(undefined!);
    
    const FormContextWrapper = (props: { children: ReactNode }) => {
        
        type ErrorState = FormContextType["errors"];
        const errorReducer = (
            errors: ErrorState,
            newError: {key: Key, error?: string }
        ): ErrorState => { 
            errors[newError.key] = newError.error;
            return errors;
        };

        type DataState = FormContextType["data"]
        const dataReducer = (
            data: DataState,
            newData: { key: Key, value: string }
        ): DataState => { 
            data[newData.key] = newData.value;
            return data;
        };


        const [toValidate, validate] = useState(false);
        const [data, updateData] = useReducer(dataReducer, {});
        const [errors, updateErrors] = useReducer(errorReducer, {})

        return <FormContext.Provider value={{
            toValidate,
            data,
            update: (
                key, value, error
            ) => {
                updateData({key, value });
                updateErrors({key, error});
            },
            errors
        }} >
            <form onSubmit={(e) => {
                e.preventDefault();
                
                validate(true);
                
                if (Object.entries(errors).every(x => x[1] === undefined)){
                    console.log("SUBMT!")
                    onSubmit(data as unknown as Model); // TODO it *SHOULD* be properly filled up
                } else {
                    console.log("ERRORS")
                    console.log(errors)
                }
            }}>
                {props.children}
            </form>
        </FormContext.Provider>

    }

    const FormInput = <Name extends keyof Model & string>(props: Omit<Parameters<typeof Input>[0], "validate" | "name" | "onChange"> & { name: Name }) => {
        
        const SpecializedInput = () => {
            const context = useContext(FormContext);
            return Input({
                ...props,
                name: props.name,
                validate: context.toValidate,
                onChange: (x) => {
                    context.update(props.name, x.value, x.error);
                }
            
            });
        };

        return <SpecializedInput/>
    }

    return {
        Form: FormContextWrapper,
        Input: FormInput,
        Context: FormContext
    };
}