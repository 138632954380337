import isbot from "isbot";
import React  from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useUser } from "../use-user";
import { useAuth } from "./use-auth";

export const redirectLogic = (auth: ReturnType<typeof useAuth>, user: ReturnType<typeof useUser>['user'], opt: {unverifiedUsers?: boolean, indexable?: boolean}) => {

  if(opt.indexable && isbot(navigator.userAgent)){ // TODO not sure this is working
    return;
  }
  else if(user !== undefined && user.onboardingAnswers === undefined){
    return '/onboarding';
  }

  if (auth.isUserVerified()){
      return;
  }
  else {
    return "/login";
  }
}

const PrivateRoute = <T extends RouteProps = RouteProps>(props: T & {unverifiedUsers?: boolean, indexable? : boolean}) => {  
    const auth = useAuth();
    const {user} = useUser();

    const unverifiedUsers = props.unverifiedUsers ?? false;
    const children = props.children;

    props = {...props}
    props.children = undefined;
    props.unverifiedUsers = undefined;

    return (
      <Route
        {...props}
        render={
          ({ location }) => 
            {
              const redirect = redirectLogic(auth, user, {unverifiedUsers, indexable: props.indexable})

              if (redirect === undefined || redirect === location.pathname) {
                return children;
              } else {
                console.log(`Redirect to ${redirect} from ${location}`)
                return <Redirect to={{pathname: redirect, state: {pendingRedirect: location}}} />
              }

          }}
      />
    );
  }

export default PrivateRoute