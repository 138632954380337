import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useEffect, useState } from "react";

export type InputValue = {value: string, error?: string};

const Input = (props: {icon?: IconDefinition, placeholder: string, type?: string, name?: string, value?: string, className?: string, footSuggestion?: string,
        onChange?: (event: InputValue) => void
        validator?: (value: string) => string | undefined
        validate?: boolean
        style?:CSSProperties
        maxLength?: number
    }
         ) => {
    const [value, setValue] = useState(props.value ?? '');
    
    const errorMsg =  props.validate === true ? props.validator?.(value) : undefined;
    
    useEffect(() => {
        // dirty trick to Force this input to be taken into consideration by the parents Form. Otherwise empty input are not verified
        props.onChange?.({ value, error: props.validator?.(value) })
    }, []);

    return <div className={"Input "+(errorMsg !== undefined ? 'error' : '') + " " + props.className ?? ""} style={props.style}><div className="input-group">
        <div className="input-group-prepend">
            <div className="input-group-text"><FontAwesomeIcon icon={props.icon ?? faPen} /></div>
        </div>
        <input 
            name={props.name}
            maxLength={props.maxLength}
            type={props.type ?? "text" }
            className="form-control"
            value={value}
            placeholder={props.placeholder}
            onChange={((e) => {
                setValue(e.target.value);
                props.onChange?.({value: e.target.value, error: props.validator?.(e.target.value)});
            })}
        ></input>
    </div>
        {errorMsg !== undefined ? <span className="error-msg">{errorMsg}</span> : undefined}
        {props.footSuggestion !== undefined && errorMsg === undefined ? <div className="foot-msg">{props.footSuggestion}</div> : undefined }
    </div>
    }

export default Input;