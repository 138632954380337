import firebase from 'firebase';
import { Redirect, useParams } from 'react-router-dom';
import { useAuth } from '../../utils/auth/use-auth';
import InfoBox from '../InfoBox';
import './style.scss';
import { GetCustomToken_Input, GetCustomToken_Output } from '../../../../shared/functions-signatures/getCustomToken';
import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { trackLogin } from '../../utils/data/tracking';

const LoginMagic = () => {
    const auth = useAuth();

    const { tk } = useParams<{tk: string}>();
    const getCustomToken = firebase.functions().httpsCallable("getCustomToken");
    const inp: GetCustomToken_Input = {
        signInToken: tk
    };
    
    const [result, setResult] = useState<GetCustomToken_Output | undefined | "awaiting" | "failed">(undefined);

    if (result === undefined) {
        console.log("Calling function with ", inp)
        setResult("awaiting");
        getCustomToken(inp) // TODO it's called twice, not sure why - UseAsync/UseEffect ?
            .then(x => {
                console.log("returned");
                console.log(x.data);
                setResult(x.data as GetCustomToken_Output);
            })
            .catch(e => {
                console.log("failed");
                console.error(e);
                setResult("failed");
            });
    }
    
    if (result === "awaiting" || result === undefined) {
        return <InfoBox title="Accesso" className="Login"><div className="loader">Caricando..</div></InfoBox>
    }
    else if (result === "failed" || result?.status === 'invalid' || result?.redirectTo === undefined) {
        Sentry.captureMessage("Invalid magic token: " + tk);
        return <Redirect to={'/'} push={false}/> 
    } else if (auth?.user !== undefined) { 
        return <Redirect to={result.redirectTo} push={false}/> 
    }    
    else if (result?.token !== undefined){ 
        firebase.auth().signInWithCustomToken(result.token)
            .then(async (result) => {
                if (result.credential !== null && result.user !== null) {
                        trackLogin({provider: "_magic"});
                    window.analytics?.identify(result.user?.uid);
                }
            }).catch((error) => {
                console.error(error);
            });
    } else if (result.status === "expired") {
        // ask to login and then redirect
        return <Redirect to={{ pathname: "/login", state: { pendingRedirect: { pathname: result.redirectTo } } }} />
    } else {
        Sentry.captureMessage("Invalid magic token: " + tk);
    }

    return <InfoBox title="Accesso" className="Login"><div className="loader">Caricando..</div></InfoBox>
}

export default LoginMagic