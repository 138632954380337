import { faAt, faKey, faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../utils/auth/use-auth';
import { trackRegistration } from '../../utils/data/tracking';
import { TEXT } from '../../utils/texts';
import { useUser } from '../../utils/use-user';
import Button from '../Button';
import InfoBox from '../InfoBox';
import Input, { InputValue } from '../Input';
import './style.scss';

const Register = () => {
    const history = useHistory();
    const location = useLocation();

    const auth = useAuth();
    const userInfo = useUser();

    const [email, setEmail] = useState<InputValue | undefined>(undefined);
    const [psw, setPsw] = useState<InputValue | undefined>(undefined);
    const [psw2, setPsw2] = useState<InputValue | undefined>(undefined);
    const [displayName, setDisplayName] = useState<InputValue | undefined>(undefined);


    const [validate, setValidate] = useState(false);

    const register = async () => {
        if (email !== undefined && email.error === undefined && 
            psw !== undefined && psw.error === undefined && 
            psw2?.value === psw.value &&
            displayName !== undefined && displayName.error === undefined &&
            auth !== undefined){ 
            const fbUser = await auth.signup(email.value, psw.value, displayName.value);
            if (fbUser !== undefined){
                await userInfo.updateUserInfo({
                    id: fbUser.uid,
                    preference: {
                        monthlyFollowUpEmail: true,
                    },
                    displayName: displayName.value,
                    registrationDateISO: DateTime.now().toISODate(),
                    email: email.value,
                    lastEmailSent: null,
                    displayNameToConfirm: false,
                    reviewCount: 0,
                });
                await fbUser.sendEmailVerification();
                trackRegistration({provider: 'email'});
                window.analytics?.identify(fbUser.uid);

                // history.push('/login'); 
                history.push('/onboarding', { pendingRedirect: location.state?.pendingRedirect }); // TODO: it should login page to handle this redirect, but it doesn't work
            }else {
                console.error("Firebase signup failed")
            }
        }
    };

    return  <InfoBox title="Registrati" className="RegisterBox">
        <form onSubmit={async (e) => { e.preventDefault(); setValidate(true); register() }}>
                    <Input placeholder="Nome Utente" icon={faUser} name="nome" onChange={setDisplayName} validate={validate} validator={
                        (v) => v.length < 3 ? "Inserisci almeno 3 caratteri" : undefined
                    }
                    footSuggestion={TEXT.suggestionNomeUtente}
                    />
                    <Input placeholder="Email" icon={faAt} name="email" onChange={setEmail}  validate={validate} validator={
                        (v) => v.length < 5 || v.search('@') === -1 ? "Inserisci un email valida" : undefined
                    }/>
                    <Input placeholder="Password" icon={faKey} type="password" onChange={setPsw} validate={validate} validator={
                        (v) => v.length < 8 ? "Inserisci una password di almeno 8 caratteri" : undefined
                    } />
                    <Input placeholder="Conferma password" icon={faKey} type="password" onChange={setPsw2} validate={validate} validator={
                        (v) => v !== psw?.value ? "Inserisci la stessa password" : undefined
                    }  />
                    
                    <p style={{fontSize: '0.7em', lineHeight: "0.9em", margin: "1em 0 2em 0"}}>Continuando, accetti <a href="https://www.iubenda.com/termini-e-condizioni/63470479" title="Termini e Condizioni" target="_BLANK" rel="noreferrer"> Termini e Condizioni</a> e  
                    <a href="https://www.iubenda.com/privacy-policy/63470479" title="Privacy Policy" target="_BLANK" rel="noreferrer"> Privacy Policy</a> 
                    </p>

                    <div style={{textAlign: "right"}}><Button type="submit" rightIcon={faChevronRight} >Continua</Button></div>
                </form>
            </InfoBox>    
}

export default Register