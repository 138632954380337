import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../utils/auth/use-auth';
import { useUser } from '../../utils/use-user';
import Button from '../Button';
import ContentWrapper from '../layout/ContentWrapper';
import './style.scss';

const TopBar = (props: {user?: boolean}) => {
    const {user} = useUser();
    const auth = useAuth();

    const userElements = auth?.user !== undefined ?
        <div><span style={{paddingRight: '0.5em'}} className="displayName">{user?.displayName ?? ""}</span> <Button onClick={() => auth.signout()}>Esci</Button></div>
        : <span></span> //<Button onClick={() => history.push('/login')}>Accedi</Button>;

    return <div className="TopBar">
            <ContentWrapper>
                <h3 style={{flexGrow: 1}}><Link to='/' className='white-link'>GiustAgenzia.it</Link></h3>
                <div>
                    {props.user === false ? '' : userElements}
                </div>
            </ContentWrapper>
            </div> 
    }

export default TopBar