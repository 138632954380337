import './style.scss';
import 'bootstrap/dist/css/bootstrap.css';
import SearchInputs from '../SearchInputs';
import TwoColumnsWrappedContent from '../layout/TwoColumnsWrappedContent';

const SearchBand = () => <div className='SearchBand'>
    <TwoColumnsWrappedContent 
        className='align-items-center'
        main={<div>
                <h4>Hai trovato l’agenzia immobiliare giusta per te? Scoprilo subito!</h4>
                <p>Lo hai già scoperto? Condividi la tua esperienza e aiuta altri utenti come te ad orientarsi</p>
            </div>}
        side={<SearchInputs />}
    />
</div>

export default SearchBand