import React, { ReactNode } from "react";
import { CSSProperties } from "react";
import FloatingOverlay from "./layout/FloatingOverlay";

 const InfoBox = (props: {
     children: ReactNode, 
     title: string, 
    //  onDismiss?: () => void, TODO
     className?: string, 
     style?: CSSProperties}) => 
    <div className={"InfoBox " + (props.className ?? "") + " vertical-fill"}>
        <FloatingOverlay>
            <div className="FloatingBox" style={props.style}>
                <div className="Title"><h2>{props.title}</h2></div>
                <div className="Content">
                    {props.children}
                </div>
            </div>
        </FloatingOverlay>
    </div>

export default InfoBox;