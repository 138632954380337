import { useHistory } from 'react-router-dom';
import { track } from '../../utils/data/tracking';
import Button from '../Button';
import TwoColumnsWrappedContent from '../layout/TwoColumnsWrappedContent';
import './style.scss';

/*
KEYWORDS:
- recensioni agenzie immobiliare +
- opinioni agenzie immobiliari
- migliore agenzia immobiliare +
- quale agenzia immobiliare scegliere

TARGET:
- chi scrive recensioni
- chi cerca informazioni
- agenzie
*/
const Main = () => {
    const history = useHistory();
    
    return <div className='Main main vertical-fill'>
        <TwoColumnsWrappedContent
            main={<div style={{position: "relative", height: "100%"}}>
                <h2>Il portale di recensioni di agenzie immobiliari</h2>
                <p>
                    Quale agenzia immobiliare scegliere non deve più essere un salto nel buio, affidati all'esperienza di altri utenti come te per scegliere la migliore agenzia immobiliare per le tue esigenze.</p><p>
                
                    <b>GiustAgenzia.it</b> permette agli utenti di condividere le proprie opinioni scrivendo recensioni per le agenzie immobiliari con cui sono entrati in contatto. Col fine ultimo di promuovere la trasparenza e la sana competizione a vantaggio della qualità del servizio.
                </p>
                <h3>Fai sentire la tua voce!</h3>
                <p>Aiutaci a rendere il mercato immobiliare meritocratico. Che tu abbia comprato, venduto, affitato o anche solo visitato un immobile lascia una recensione. Se sei soddisfatto premia l’agenzia e aiuta altri utenti a usufruire dell’ottimo servizio. Se non è andata bene metti in guardia altri utenti condividendo la tua esperienza.</p>
                <p><br /></p> {/* Leaving some space for the absolute positioned button. TODO some better approach*/}
                
                <Button onClick={() => {
                    history.push('/nuova-recensione')
                    track("NEW_REVIEW_START");
                }
                } style={{ width: "100%", position: "absolute", bottom: 0 }}>Aggiungi una Recensione</Button>
                
            </div>}
            // https://www.photocase.com/photos/3944663-hand-holds-a-small-painted-house-concept-house-construction-and-home-ownership-dot-photocase-stock-photo
            side={<img src="home.png" style={{ width: '100%', paddingTop: "2em" }} alt="fill" />}
        />
    </div>
}

export default Main