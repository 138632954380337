import { parseCsvFromFirebase } from "../firebase";

export type Suggestion = {
    label: string,
    id: string,
}

export type AreaSuggestion = Suggestion & {provincia: string, is_provincia: boolean};
export type AgenziaSuggestion = Suggestion & {indirizzo: string, 'id-area': string, sigla: string};

export const getAreas = async(): Promise<AreaSuggestion[]> => {
    
    return await parseCsvFromFirebase<AreaSuggestion>('public/completion_aree.csv', 
        {
            'is_provincia': (x: string) => x === "True"
        }
    );
}

export const getAgenziaSuggestion = async(area: AreaSuggestion) => {
    const data = parseCsvFromFirebase<AgenziaSuggestion>('public/completion_agenzie/' + area.provincia +".csv");
    return data.then(data => data.filter((ag) => {
        if (area.is_provincia) {
            return true;
        } else {
            return area.id === ag['id-area'];
        }
    }));
}