import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseUser, faMapMarkerAlt, faPlus, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { AgenziaSuggestion, AreaSuggestion, getAgenziaSuggestion, getAreas } from '../utils/data';
import { AutocompletedInput, SimpleSuggestionsProvider, SuggestionsProvider } from './AutocompletedInput';
import { usePageInfo } from '../utils/use-page-info';
import { track, trackSearchAgenzia, trackSearchArea } from '../utils/data/tracking';


const getCompletionAgenzie = async(area: AreaSuggestion) => {
    return new SimpleSuggestionsProvider(getAgenziaSuggestion(area));
}

const areaSuggestionsProvidider = new SimpleSuggestionsProvider(getAreas());



// TODO populate using :id (for those navigaitng directly)
const SearchInputs = (props: {onSelect?: (agenzia: AgenziaSuggestion) => void}) => {
    const [selectedLocation, setLocationSelected] = useState<AreaSuggestion | undefined>(undefined);
    const [suggestionAgenzie, setSuggestionAgenzie] = useState<SuggestionsProvider<AgenziaSuggestion> | undefined>(undefined);
    
    const history = useHistory();
    const { pageInfo, setPageInfo } = usePageInfo();

    return <div className='SearchInputs'>
        <AutocompletedInput 
            id="inputArea"
            placeholder="Inserisci area di ricerca (Comune o Provincia)" 
            faIcon={faSearchLocation}
            baseZIndex={2}
            initialValueId={pageInfo?.idArea}
            onSelect={(area, isUserSelected) => {
                if (isUserSelected) {
                    trackSearchArea({
                        area: area.id,
                        label: area.label
                    });
                    // if !isUserSelected it should come from pageInfo
                    setPageInfo({
                        idArea: area.id,
                    })    
                }
                
                setLocationSelected(area); 
                setSuggestionAgenzie(undefined);
                getCompletionAgenzie(area).then((s) => {
                    setSuggestionAgenzie(s);
                    if (isUserSelected) {
                        (document.querySelector('#inputAgenzia') as HTMLInputElement).focus();
                    }
                });
                }
            }
            suggestionProvider={areaSuggestionsProvidider}
            suggestionPresenter={(suggestion, isSelected, isHighlighted) => 
                <div style={{display: "flex"}}>            
                    <div className="input-group-text"><FontAwesomeIcon icon={faMapMarkerAlt} /></div>
                    <span>{suggestion.label} ({suggestion.is_provincia ? "Provincia" : suggestion.provincia})</span>
                </div>
                }
            />
        <AutocompletedInput 
            id="inputAgenzia"
            placeholder="Inserisci nome agenzia immobiliare"
            initialValueId={suggestionAgenzie === undefined ? undefined : pageInfo?.idAgenzia}  // dirty fix to avoid trying to set Ag when no data is yet available
            onSelect={(agenzia, isUserSelected) => {
                if (isUserSelected) {
                    trackSearchAgenzia({
                        agenzia: agenzia.id,
                        label: agenzia.label
                    });    
                    
                    // if !isUserSelected than it comes from pageInfo
                    setPageInfo({
                        idAgenzia: agenzia.id,
                        idArea: agenzia['id-area']  // TODO this probably is not what the user inserted
                    });

                    if (props.onSelect !== undefined) {
                        props.onSelect(agenzia);
                    } else {
                        history.push(`/recensioni-agenzia-immobiliare/${agenzia.sigla}/${agenzia.id}`)
                    }
                }
                
            }} // TODO add SEO frindly name to url?
            faIcon={faHouseUser}
            baseZIndex={1}
            style={{
                opacity: selectedLocation !== undefined ? 1 : 0, 
                visibility: selectedLocation !== undefined ? "visible" : "hidden", 
                transition: "opacity 1s"}}
            suggestionProvider={suggestionAgenzie}
            suggestionPresenter={(suggestion, isSelected, isHighlighted) => 
                <div style={{display: "flex"}}>            
                    <div className="input-group-text"><FontAwesomeIcon icon={faHouseUser} fixedWidth={true}/></div>
                    <span>{suggestion.label}</span>
                </div>
                }
            addNew={{
                handler: () => {
                    track("NEWAG_START");
                    history.push("/aggiungi-agenzia");
                 },
                menuItemChild: <div style={{display: "flex"}}>            
                    <div className="input-group-text"><FontAwesomeIcon icon={faPlus} fixedWidth={true} /></div>
                    <span>Aggiungi nuova agenzia</span>
            </div>
            }}
            />
    </div>
}
export default SearchInputs

