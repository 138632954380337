import { faAt, faKey, faUserPlus, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../utils/auth/use-auth';
import { track, trackLogin } from '../../utils/data/tracking';
import Button from '../Button';
import InfoBox from '../InfoBox';
import Input, { InputValue } from '../Input';
import MessageBox from '../MessageBox';
import './style.scss';

// Base entry point for all the user registration/login related routing
const Login = () => {
    const history = useHistory();
    const location = useLocation();
    
    const auth = useAuth();

    const [email, setEmail] = useState<InputValue | undefined>(undefined);
    const [psw, setPsw] = useState<InputValue | undefined>(undefined);
    const [validate, setValidate] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [resetSent, setResetSent] = useState(false);

    const login = async () => {
        if (process.env.NODE_ENV !== "production") {
            const { CREDENTIAL } = await import("../../CREDENTIAL");
            await auth?.signin(CREDENTIAL.user, CREDENTIAL.psw)
            return;
        }

        if (email!== undefined && email.error === undefined && psw !== undefined && psw.error === undefined){
            let user = null;
            try {
                user = await auth?.signin(email.value, psw.value)
                if (user !== undefined){
                    if (user.emailVerified){
                        trackLogin({provider: 'email'});
                    } else {
                        track("USER_UNVERIFIED_LOGIN");
                    }
                    
                    window.analytics?.identify(user.uid);
                }
            } finally {
                if (user === null){
                    setError("Email o password non corretti");
                }
            }
            
        }
    }

    const resetPassword = async () => {
        let sent = false;
        if(email !== undefined && email.error === undefined && auth !== undefined) {
            try {
                sent = await auth?.sendPasswordResetEmail(email.value);
            }catch (e){
                if (e.code === "auth/user-not-found"){
                    // ignoring bad requests for unregistered emails
                    sent = true;
                } else {
                    sent = false;   
                }
            }   
        }
        
        if (!sent) {
            setError("Inserisci un email valida");
        } else {
            setError(undefined);
            setResetSent(true);
        }
        
    }

    if(auth?.user !== undefined){
        return <Redirect to={location.state?.pendingRedirect ?? '/'} push={false}/>
    }
    else { 
        
        return <InfoBox title="Accedi" className="Login">
                <form onSubmit={async (e) => {e.preventDefault(); setValidate(true); login()} }>
                    <Input placeholder="Email" icon={faAt} name="email" onChange={setEmail} validate={validate} validator={
                        (v) => v.length < 5 || v.search('@') === -1 ? "Inserisci un email valida" : undefined
                    }/>
                    <Input placeholder="Password" icon={faKey} type="password" onChange={setPsw} validate={validate} validator={
                        (v) => v.length === 0 ? "Inserisci una password" : undefined
                    } />
                    <div style={{fontSize: "small", textAlign: "right"}}>{
                        !resetSent ?
                        <a  href="" onClick={(e) => {
                            e.preventDefault(); 
                            resetPassword();
                            track("USER_RESET_PASSWORD");
                        }}>Password dimenticata?</a> :
                        <MessageBox>Controlla la tua casella email per reimpostare la password</MessageBox>
                        }</div>
                    {error ? <MessageBox error>{error}</MessageBox> : undefined}
                    <br/>
                    <div style={{display: "flex"}}>
                    <Button type="button" className="signupButton" leftIcon={faUserPlus} style={{ width: "100%" }} onClick={() =>
                        history.push('/register', { pendingRedirect: location.state?.pendingRedirect })}>registrati</Button>
                        <Button type="submit" className="signinButton" leftIcon={faSignInAlt} style={{width: "100%"}}>accedi</Button>
                    </div>
                </form>
                </InfoBox>
    }
}

export default Login