import React from "react";
import StarsComp from "./Stars";

const WithStars = (props: {
        children: JSX.Element, 
        starsProp: Parameters<typeof StarsComp>[0],
        style?: React.CSSProperties
        }) => 
    {
        return <div  className="WithStars" style={{ display: "flex", flexWrap: "wrap-reverse", ...props.style }}>

            <div style={{ flexGrow: 1, margin: 0 }}>
                {props.children}
            </div>
            <StarsComp {...props.starsProp} />
        </div>;
    }

export default WithStars;