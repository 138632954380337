import ContentWrapper from "./ContentWrapper";
import './style.scss';

const TwoColumnsWrappedContent = (props: {
    className?: string,
    main: JSX.Element,
    side?: JSX.Element,    
    // mainStyle: React.CSSProperties,
    // sideStyle: React.CSSProperties,
    mainClassNames?: string,
    sideClassNames?: string,
    alignSideRight?: boolean,
    flexGrow?: boolean
}) => 
    <ContentWrapper className={props.flexGrow ? 'vertical-fill' : undefined}>
        <div className={"row no-gutters " + (props.className ?? '')}>
            <div className={"col-lg-8 maincol "+ (props.mainClassNames ?? '')}>{props.main}</div>
            <div className={"col-lg-4 sidecol "+ (props.sideClassNames ?? '')} style={{textAlign: props.alignSideRight ? 'right' : undefined}}>{props.side}</div>
        </div>
    </ContentWrapper>

export default TwoColumnsWrappedContent;