import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { MenuItem, TextareaAutosize, FormControl, FormHelperText} from '@material-ui/core';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { uploadRequest } from '../../utils/firebase';
import Button from '../Button';
import InfoAgenzia from '../InfoAgenzia';
import TwoColumnsWrappedContent from '../layout/TwoColumnsWrappedContent';
import Select from '../Select';
import WithStars from '../WithStars';
import Checkbox from '../Checkbox';
import './style.scss';
import { useAsync } from "react-async"
import Input, { InputValue } from '../Input';
import { useUser } from '../../utils/use-user';
import firebase from 'firebase';
import { ReviewWriteModel, TransactionLevel, TransactionsTypeModel } from '../../../../shared/data-model/reviews';
import { AgenziaReadModel } from '../../../../shared/data-model/agenzia';
import { FirebaseRequest } from '../../../../shared/data-model/requests';
import MessageBox from '../MessageBox';
import { usePageInfo } from '../../utils/use-page-info';
import { track } from '../../utils/data/tracking';

type Model = Partial<Omit<ReviewWriteModel, 'title'> & {title?: InputValue}> & {checkbox?: boolean}

type State = {
    data: Model,
    errors: {[key in keyof Model]?: string;},
    submitted: boolean,
}

// TODO no need to reload everything
const loadReviews = async (id: string) => {
    const agenzia = await firebase.firestore().collection('agenzie').doc(id).get();
    return agenzia.data() as AgenziaReadModel;
}

const NewReview = () => {
    const MIN_REVIEW_SIZE = 50;
    const MAX_REVIEW_SIZE = 500;
    
    const {user} = useUser();

    const { id, provincia } = useParams<{ id: string, provincia: string }>();
    
    const { setPageInfo } = usePageInfo();
    useEffect(() => {
        setPageInfo({
            idArea: provincia.toLowerCase(),
            idAgenzia: id,
        });
        return () => {
            setPageInfo({
                idArea: provincia.toLowerCase(),
                // clear agenzia
            });
        }
    }, []);

    const {data } = useAsync({
        promiseFn: useCallback(() => loadReviews(id), [id])
    });
    
    const [submitState, setSubmitState] = useState<undefined | "ongoing" | "done" | "error">();

    const reducer = (state: State, action: {op: "submit" | "input", patch?: Model}): State => {
        if (action.op === "submit"){
            state.submitted = true;
        } else if (action.patch !== undefined) {
            const patch = action.patch;
            const updated = {
                stars: patch.stars ?? state.data.stars, 
                review: (patch.review ?? state.data.review)?.slice(0, MAX_REVIEW_SIZE), 
                transactionType: patch.transactionType ?? state.data.transactionType, 
                transactionLevel: patch.transactionLevel ?? state.data.transactionLevel, 
                checkbox: patch.checkbox ?? state.data.checkbox, 
                title: patch.title ?? state.data.title,
                };
            state.data = updated;
        }

        if (state.submitted) {
            state.errors.stars = state.data.stars === undefined ? "Seleziona una valutazione" : undefined;
        
            if((state.data.review?.length ?? 0) < MIN_REVIEW_SIZE){
                state.errors.review = `Scrivi una recensione di almeno ${MIN_REVIEW_SIZE} caratteri`;
            } else {
                state.errors.review = undefined;
            }

            state.errors.transactionType = state.data.transactionType === undefined ? "Seleziona una tipologia" : undefined;
            state.errors.transactionLevel = state.data.transactionLevel === undefined ? "Seleziona una tipologia" : undefined;
            state.errors.checkbox = state.data.checkbox ?? false  ? undefined : "Accetta per proseguire";
            state.errors.title = state.data.title?.error;

            if (Object.entries(state.errors).every(x => {
                if (x !== undefined){
                    const [k, v] = x;
                    if (k === 'transactionLevel' && state.data.transactionType === 'altro'){
                        return true;
                    } else {
                        return v === undefined;
                    }
                }
                return true;
            }) &&
                action.op === 'submit' &&
                submitState === undefined
                ){
                    setSubmitState("ongoing");
                    if (user !== undefined){
                        const data: ReviewWriteModel = {
                            title: state.data.title!.value,
                            review: state.data.review!,
                            stars: state.data.stars!,
                            transactionLevel: state.data.transactionLevel!,
                            transactionType: state.data.transactionType!,
                        };

                        const rq: FirebaseRequest = {
                            type: 'new-review',
                            review: data,
                        }
                        
                        uploadRequest(`${user.id}_${id}`, rq).then(() => {
                            track("NEW_REVIEW_SUCCESS");
                            setSubmitState("done");
                        }).catch((e)=> {
                            console.error(e);
                            setSubmitState("error");
                        })
                    }
            }
        }
        
        return {...state};
    }

    const [state, update] = useReducer(reducer, {
        data: {},
        errors: {},
        submitted: false
    } as State)

    let mainCont = <div></div>;
    if (data !== undefined && submitState === undefined) {
        mainCont = <div>
        <div className={state.errors.stars !== undefined ? 'error' : undefined}>
            <WithStars 
                starsProp={{
                    stars: 0, 
                    onSelect: (x)=> {
                        update({op: "input", patch: {stars: x}});
                    }, 
                    starStyle: {fontSize: '1.5em'}}} 
                style={{flexWrap: 'wrap'}}
                >
                <h2>{data.nome}</h2>
            </WithStars>
            <span className="error-msg">{state.errors.stars}</span>
        </div><br/>
        <Input placeholder="Titolo recensione" name="titolo"
            maxLength={40}
            onChange={(x) => update({op: 'input', patch: {title: x}})}
            validate={state.submitted} 
            validator={
                (v) => v.length === 0 ? "Scrivi un titolo" : undefined
            }
        />
        <FormControl className={state.errors.review !== undefined ? 'error' : undefined} style={{marginTop: 0}}>
            <TextareaAutosize placeholder="La tua recensione..." rowsMin={3} style={{borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}
            onChange={(x) => {
                update({op: "input", patch:{review: x.target.value}});
            }}
            value={state.data.review} />
            <FormHelperText style={{textAlign: 'left'}}>
                <div style={{display: "flex"}}>
                    <span className="error-msg">{state.errors.review}</span>
                    <span style={{flexGrow: 1}}></span>
                    <span>{state.data.review?.length ?? 0}/{MAX_REVIEW_SIZE}</span>
                </div>
            </FormHelperText>
        </FormControl>

        <FormControl>
        <Select label={"Cosa ti ha portato da "+data.nome} onChange={(x) => 
            {
                update({op: "input", patch:{transactionType: x as TransactionsTypeModel}});
            }}  error={state.errors.transactionType !== undefined}>
            <MenuItem value="comprare">Comprare un immobile</MenuItem>
            <MenuItem value="vendere">Vendere un immobile</MenuItem>
            <MenuItem value="dare-in-affitto">Dare in affitto un immobile</MenuItem>
            <MenuItem value="prendere-in-affitto">Prendere in affitto un immobile</MenuItem>
            <MenuItem value="altro">Altro</MenuItem>
        </Select>
        </FormControl>
        
        <FormControl style={{display: state.data.transactionType === "altro" || state.data.transactionType === undefined ? "none" : "inherit"}}>
        <Select label="A che punto sei arrivato nella trattativa?" onChange={(x) => update({op: "input", patch:{transactionLevel: x as TransactionLevel}})} 
            error={state.errors.transactionLevel !== undefined}>
            <MenuItem value={"contatto"}>Contatto iniziale</MenuItem>
            <MenuItem value={"visita"}>Visita immobile</MenuItem>
            <MenuItem value={"trattative"}>Almeno una trattativa iniziata</MenuItem>
            <MenuItem value={"conclusa"}>Trattativa conclusa (compra-vendita o affitto)</MenuItem>
        </Select >
        </FormControl>
        
        <Checkbox 
            validate={state.errors.checkbox !== undefined}
            onChange={(x) => update({op: "input", patch: {checkbox: x}})}>
                <span>Dichiaro che questa recensione rappresenta la mia opinione reale ed autentica riguardo alla mia personale esperienza con {data.nome}. Dichiaro inoltre che non ho alcun interesse personale o professionale che mi lega a {data.nome} e che nessun incentivo o pagamento mi è stato offerto per pubblicare la recensione in oggetto. Sono altresi consapevole che GiustAgenzia.it non tollera recensioni false e che secondo la legislazione italiana queste possono constituire un reato con pena fino ad un anno di reclusione<a href="https://www.consulenzalegaleitalia.it/false-recensioni-online/" target="_BLANK" rel="noreferrer"> (scopri di più)</a>.</span>
        </Checkbox>

        <div style={{display: "flex"}}>
            <div style={{flexGrow: 1}}/>
            <Button rightIcon={faChevronRight} onClick={() => update({op: 'submit'})}>aggiungi</Button>
        </div>
    </div>;
    } else if(submitState === 'ongoing'){
        mainCont = <MessageBox>Stiamo caricando la tua recensione...</MessageBox>
    } else if(submitState === 'done'){
        mainCont = <MessageBox>La tua recensione è stata caricata, presto la vedrai online, grazie!</MessageBox>
    } else if(submitState === 'error'){
        mainCont = <MessageBox>Ci dispiace, qualcosa è andato storto. Riprova più tardi o contatta l'assistenza.</MessageBox>
    }

    
        return <TwoColumnsWrappedContent className='main NewReview' 
        flexGrow
        main={mainCont}
        side={data !== undefined ? <InfoAgenzia 
            address={data.indirizzo.full}
            name={data.nome}
        /> : <div></div>}
    />
}

export default NewReview
