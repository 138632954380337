import './style.scss';
import 'bootstrap/dist/css/bootstrap.css';
import ContentWrapper from '../layout/ContentWrapper';

const TitleBand = (props: { title: string }) => <div className='SearchBand'>
    <ContentWrapper>
    <div
            style={{
                paddingLeft: '1em'  // TODO no hardcoded dimension!
            }}
        >
            <h4>{props.title}</h4>
                
            </div>
        
    </ContentWrapper>
</div>

export default TitleBand