export const track = (event:
    "NEW_REVIEW_START" |
    "NEW_REVIEW_SUCCESS" |
    "EMAIL_UNSUBSCRIBE_FOLLOWUP" |
    "NEWAG_START" |
    "NEWAG_SUBMIT" |
    "USER_UNVERIFIED_LOGIN" |
    "USER_RESET_PASSWORD" 
) => {
    window.analytics?.track(event);
}

export const trackSearchArea = (prop: {
    area: string,
    label: string,
}) => {
    window.analytics?.track( "SEARCH_AREA", prop);  
}

export const trackSearchAgenzia = (prop: {
    agenzia: string,
    label: string,
}) => {
    window.analytics?.track( "SEARCH_AGENZIA", prop);  
}

export const trackLogin = (prop: {
    provider: string,
}) => {
    window.analytics?.track( "USER_LOGIN", prop);  
}

export const trackOnboarding = (prop: { // TODO actually type
    userPersona: string,
    otherPersona?: string,
    contatti?: string,
}) => {
    window.analytics?.track( "USER_ONBOARDING", prop);  
}

export const trackRegistration = (prop: {
    provider: string,
}) => {
    window.analytics?.track( "USER_REGISTER", prop);  
}
