import { useCallback } from 'react';
import { useAsync } from 'react-async';
import { track } from '../../utils/data/tracking';
import { useUser } from '../../utils/use-user';
import ContentWrapper from '../layout/ContentWrapper';
import './style.scss';

const EmailUnsubscribeLanding = () => {
    const { user, updateUserInfo } = useUser();
    
    const { status, error } = useAsync(useCallback(async () => {    // useCallback used to run this only once
        if (user !== undefined) {
            user.preference.monthlyFollowUpEmail = false;
            await updateUserInfo(user);
            track("EMAIL_UNSUBSCRIBE_FOLLOWUP");
        }
    }, [user, updateUserInfo])); 

    let text = "elaborazione richiesta in corso...";
    if (error !== undefined) {
        text = "qualcosa è andato storto";
    } else if (status === "fulfilled") {
        text = "Non riceverai più email del genere :)";
    }

    return <div className='Main main vertical-fill' style={{textAlign: "center"}}>
        <ContentWrapper>
            {text}    
        </ContentWrapper>
    </div>
}

export default EmailUnsubscribeLanding