import firebase from "firebase";

export const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA6Ac1IdHGdGWdz4siiC7X3Q88SBrBNwtI",
  authDomain: "giustagenzia.it",
  projectId: "giustagenzia",
  storageBucket: "giustagenzia.appspot.com",
  messagingSenderId: "531402062619",
  appId: "1:531402062619:web:e0be996e391ea7defd71be",
  measurementId: "G-P3Y3ZQGRVP"
});

firebase.firestore().settings({
  ignoreUndefinedProperties: true,
})