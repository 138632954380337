import ContentWrapper from '../layout/ContentWrapper';
import './style.scss';
import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => <div className='Footer'>
    <ContentWrapper className="show-on-lg">
    <p style={{textAlign: 'right'}}>© {DateTime.now().year} GiustAgenzia.it | 
     <a href="https://www.iubenda.com/privacy-policy/63470479" title="Privacy Policy" target="_BLANK" rel="noreferrer" className="white-link"> Privacy Policy</a> - 
    <a href="https://www.iubenda.com/privacy-policy/63470479/cookie-policy" title="Cookie Policy" target="_BLANK" rel="noreferrer" className="white-link"> Cookie Policy</a> - 
    <a href="https://www.iubenda.com/termini-e-condizioni/63470479" title="Termini e Condizioni" target="_BLANK" rel="noreferrer" className="white-link"> Termini e Condizioni</a> | 
     <Link to="/agenzie" className="white-link"> Tutte le agenzie</Link>
    </p>
    </ContentWrapper>
    <ContentWrapper className="hide-on-lg">
    <p style={{textAlign: 'right'}}>© {DateTime.now().year} GiustAgenzia.it<br/>
     <a href="https://www.iubenda.com/privacy-policy/63470479" title="Privacy Policy" target="_BLANK" rel="noreferrer" className="white-link"> Privacy Policy</a> - 
    <a href="https://www.iubenda.com/privacy-policy/63470479/cookie-policy" title="Cookie Policy" target="_BLANK" rel="noreferrer" className="white-link"> Cookie Policy</a> - 
    <a href="https://www.iubenda.com/termini-e-condizioni/63470479" title="Termini e Condizioni" target="_BLANK" rel="noreferrer" className="white-link"> Termini e Condizioni</a> <br/>
     <Link to="/agenzie" className="white-link"> Tutte le agenzie</Link>
    </p>
    </ContentWrapper>
    </div>

export default Footer