
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import './style.scss';
import Button from '../Button';
import { DateTime } from 'luxon';
import TwoColumnsWrappedContent from '../layout/TwoColumnsWrappedContent';
import InfoAgenzia from '../InfoAgenzia';
import WithStars from '../WithStars';
import { useAsync } from "react-async"
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase';
import {ReviewReadModel} from '../../../../shared/data-model/reviews';
import { AgenziaReadModel } from '../../../../shared/data-model/agenzia';
import { useEffect } from 'react';
import { redirectLogic } from '../../utils/auth/PrivateRoute';
import { useAuth } from '../../utils/auth/use-auth';
import { useUser } from '../../utils/use-user';
import isbot from 'isbot';
import { usePageInfo } from '../../utils/use-page-info';
import { track } from '../../utils/data/tracking';

const ReviewComp = (info: ReviewReadModel, id: number) => 
    <div className='Review' key={id}>
        <WithStars starsProp={{stars:info.stars}}>
            <h3 style={{flexGrow: 2}}>{info.title}</h3>
        </WithStars>
        <p className="body">{info.review}</p>
        <p className="footer">{
        `${info.username} - ${DateTime.fromISO(info.dateISO).setLocale('it').toRelative()} - ${
            {
                'prendere-in-affitto': 'cercando un affitto',
                'altro': 'altro',
                'comprare': 'comprando',
                'vendere': "vendendo",
                'dare-in-affitto': 'cercando un affituario'
            }[info.transactionType]
        }`
        }</p>
    </div>

const EmptyReviewComp = () => 
    <div className='Review zstack'>
        <FontAwesomeIcon icon={faCommentSlash} style={{ color: '#F6F7F8', fontSize: "10em" }} />
        {
            isbot(navigator.userAgent) ? "" : <p style={{ textAlign: 'center' }}>
                Nessuna recensione per quest’agenzia.<br/>
                Puoi essere tu il primo ?
            </p>
        }
    </div>

const loadReviews = async (id: string) => {
    const agenzia = await firebase.firestore().collection('agenzie').doc(id).get();
    console.log(agenzia.data())
    const recensioni = await firebase.firestore().collection('agenzie').doc(id).collection('reviews').get();
    console.log(recensioni);
    let result = agenzia.data() as AgenziaReadModel;
    result.recensioni = recensioni.docs.map((x) => {
        console.log(x.data());
        return x.data() as ReviewReadModel;
    })
    return result
}

const Reviews = () => {
    let { id, provincia } = useParams<{ id: string, provincia: string }>();

    const { setPageInfo } = usePageInfo();

    useEffect(() => {
        setPageInfo({
            idArea: provincia.toLowerCase(),
            idAgenzia: id,
        });

        return () => {
            setPageInfo({
                idArea: provincia.toLowerCase(),  // clear agenzia
            });
        }
    }, [id, provincia]);

    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();
    const {user} = useUser();

    const {data, error} = useAsync({
        promiseFn: useCallback(() => loadReviews(id), [id])
    });

    if (error !== undefined){
        console.error(error);
    }

    useEffect(()=>{
        if (data !== undefined){
            document.title = `Recensioni ${data.nome} | GiustAgenzia.it`
        }
    }, [data])

    if (data !== undefined){

        const AddReviewBt = <Button 
            leftIcon={faComment} 
            style={{width: "100%"}}
            onClick={(e) => {
                track("NEW_REVIEW_START");
                history.push(`/nuova-recensione/${provincia}/${id}`)
            }}   
            >
            aggiungi la tua recensione
            </Button>
        
        let mainEl;
        const redirect = redirectLogic(auth, user, {indexable: true});

        if (redirect === undefined){
            mainEl = 
                <div>
                    {
                        // TODO handle no stars (i.e. reviews) properly
                    } 
                    <WithStars starsProp={{stars: data.stars ?? 0, starStyle: {fontSize: "1.5em", color: data.recensioni.length === 0 ? 'white' : 'inherit'}}} style={{minHeight: "3em", marginBottom: '1em'}}>
                        <h1 style={{ margin: 0}}>
                            <span style={{display: "inline-block", paddingRight: "0.3em"}}>Recensioni &#32;</span> 
                            <span style={{display: "inline-block"}}> {data.nome}</span>
                        </h1>
                    </WithStars>
                    <div className="hide-on-lg" style={{marginBottom: "1.5em"}}>{AddReviewBt}</div>
                    { data.recensioni.length > 0 ? data.recensioni.map((r, i) => ReviewComp(r, i)) : EmptyReviewComp()}
                    <div className="show-on-lg">{AddReviewBt}</div>
                </div>
        } else if (redirect === '/login') {
            if (!auth.isUserVerified()) {
                return <Redirect to={{pathname: '/login', state: {pendingRedirect: location}}}/>
            }
            mainEl = <div>
                <WithStars starsProp={{stars: 5, starStyle: {fontSize: "1.5em", color: 'white'}}} style={{minHeight: "3em", marginBottom: '1em'}}>
                    <h1 style={{ margin: 0}}>
                        <span style={{display: "inline-block", paddingRight: "0.3em"}}>Recensioni &#32;</span> 
                        <span style={{display: "inline-block"}}> {data.nome}</span>
                    </h1>
                </WithStars>
                <div className='Review' style={{textAlign: "center", padding: '1.5em', fontSize: '1.1em'}}>
                    <b>Accedi</b> per visualizzare le recensioni di {data.nome}<br /><br />
                    
                    <Button style={{ width: '100%' }} onClick={() =>
                        history.push('/login', { pendingRedirect: location })}>Accedi</Button>  
                </div>
            </div>
          
        } else if (redirect === '/onboarding') {    // TODO centralize this logic in the router
            return <Redirect to={{pathname: '/onboarding', state: {pendingRedirect: location}}}/>
        } else {
            mainEl = <div></div>
        }
            
        const sideEl = <div>
            <InfoAgenzia 
                address={data.indirizzo.full}
                name={data.nome}
                subheader={<div style={{minHeight: "4em"}}>{AddReviewBt}</div>}
            />
            </div>

        return <TwoColumnsWrappedContent 
            className='main Reviews'
            main={mainEl}
            side={sideEl}
            flexGrow
            />
    } else return <TwoColumnsWrappedContent
        flexGrow
        main={<div className="main"></div>}
    />
    

}

export default Reviews