// https://usehooks.com/useAuth/ 

import React, { useState, useContext, createContext, ReactNode } from "react";

import firebase from "firebase";
import { User } from "../../../shared/data-model/user";
import { useAuth } from "./auth/use-auth";

function useProvideUser() {
  
  const auth = useAuth();

  const getUserDoc = async (id: string) => await firebase.firestore().collection('users').doc(id).get();
  const getUser = async (id: string): Promise<User> => {
    const userData = (await getUserDoc(id)).data() as User;

      return { 
        id,
        preference: {
          monthlyFollowUpEmail: userData?.preference.monthlyFollowUpEmail ?? true,
        },
        registrationDateISO: userData?.registrationDateISO,
        displayName: userData?.displayName ?? "",
        email: userData?.email ?? "",
        lastEmailSent: userData?.lastEmailSent ?? null,
        onboardingAnswers: userData?.onboardingAnswers,
        displayNameToConfirm: userData?.displayNameToConfirm ?? false,
        reviewCount: userData?.reviewCount ?? 0,  // some legacy users might have this uninitializated 
      }
    }

  const [user, setUser] = useState<User|undefined>(undefined);

  if (auth.user !== undefined && user === undefined){
    // async init
    getUser(auth.user.uid).then((u)=>{
      setUser(u)
    })
  }else if (auth.user === undefined && user !== undefined){
    //cleanup
    setUser(undefined);
  }

  const updateUserInfo = async (info: User) => {
    const doc = await getUserDoc(info.id);
    await doc.ref.set(info);
    setUser(info);
    return info;
  };

  // Return the user object and auth methods
  return {
    user,
    updateUserInfo
  };
}

const userContext = createContext<ReturnType<typeof useProvideUser>>(undefined!);  // https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideUser(props: { children: ReactNode }) {
  const user = useProvideUser();
  return <userContext.Provider value={user}>{props.children}</userContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useUser = () => {
  return useContext(userContext);
};
