import { CSSProperties, ReactNode } from "react";
import './style.scss';

const AllCentered = (props: {children: ReactNode, className?: string, style?: CSSProperties}) =>
    <div className={"AllCentered " + (props.className !== undefined ? props.className : '' )} style={props.style}>
        <div style={{flexGrow: 1}}></div>
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{flexGrow: 1}}></div>
            {props.children}
            <div style={{flexGrow: 1}}></div>
        </div>
        <div style={{flexGrow: 1}}></div>
    </div>

export default AllCentered;