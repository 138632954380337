import { faEnvelope, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import firebase from 'firebase';
import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../utils/auth/use-auth';
import { useUser } from '../../utils/use-user';
import Button from '../Button';
import InfoBox from '../InfoBox';
import './style.scss';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { LocationDescriptorObject } from "history";
import { trackLogin, trackRegistration } from '../../utils/data/tracking';

const STORAGE_KEY = "Login:RedirectTo";
const DEFAULT_REDIRECT: LocationDescriptorObject = {
    pathname: "/"
};

// Base entry point for all the user registration/login related routing
const Login = () => {
    const history = useHistory();
    const location = useLocation();
    
    
    let onSuccessRedirectTo = DEFAULT_REDIRECT;
    if (location.state?.pendingRedirect !== undefined) {
        onSuccessRedirectTo = location.state.pendingRedirect;
    }
    else {
        const stored = localStorage.getItem(STORAGE_KEY);
        if (stored !== null) {
            onSuccessRedirectTo = JSON.parse(stored);
        }
    }
    console.log("Login needed for", onSuccessRedirectTo.pathname);

    let cleanLocalStorage = false;
    
    useEffect(()=>{
        return ()=>{
            if (cleanLocalStorage){
                localStorage.removeItem(STORAGE_KEY); // to avoid refreshing the component, and thus redirecting to `/`.
            }
        }
    }, [])
   
    const auth = useAuth();
    const {updateUserInfo} = useUser();
    const [isWaitingRedirect, setIsWaitingRedirect] = useState(true);

    const [verifSent, setVerifSent] = useState(false);

    if (auth.user?.emailVerified === false &&
        !auth.user.providerData.find(d => d && d.providerId === 'facebook.com')){  // https://github.com/firebase/firebase-js-sdk/issues/340
        
        localStorage.setItem(STORAGE_KEY, JSON.stringify(onSuccessRedirectTo)); // will refresh page, loosing state
        return <InfoBox title="Verifica Indirizzo Email">
            <p >Controlla la tua casella di posta per verificare il tuo indirizzo. Se lo hai già fatto aggiorna la pagina.</p>
            <div style={{textAlign: "right"}}>
            <Button leftIcon={verifSent ? faCheck : faEnvelope} onClick={async () => {
                if (!verifSent){
                    await auth.user?.sendEmailVerification();
                    setVerifSent(true);
                }
            }}> {verifSent ? "Fatto" : "Rimanda email"}</Button></div>
            </InfoBox>
    } 
    else if(auth?.user !== undefined){
        cleanLocalStorage = true;
        console.log("Redirect to", onSuccessRedirectTo)
        return <Redirect to={onSuccessRedirectTo} push={false}/>
    }
    else { 
        
        firebase.auth()
            .getRedirectResult()
            .then(async (result) => {
                if (result.credential !== null && result.user !== null) {
                    if(result.additionalUserInfo?.isNewUser === true){
                        trackRegistration({provider: result.credential.providerId});
                        updateUserInfo({
                            id: result.user.uid,
                            preference: {
                                monthlyFollowUpEmail: true,
                            },
                            registrationDateISO: DateTime.now().toISODate(),
                            reviewCount: 0,
                            lastEmailSent: null,
                            email: result.user.email ?? '',
                            displayName: result.user.displayName ?? '', // TODO? let the user change its display name from the profile page
                            displayNameToConfirm: true,
                        })
                    } else {
                        trackLogin({provider: result.credential.providerId});
                    }
                    window.analytics?.identify(result.user?.uid);
                }
                setIsWaitingRedirect(false);
                
            }).catch((error) => {
                console.error(error);
            });
        
        const signInWithRedirect = (provider: firebase.auth.AuthProvider) => {
            firebase.auth().languageCode = 'it';
            setIsWaitingRedirect(true);
            localStorage.setItem(STORAGE_KEY, JSON.stringify(onSuccessRedirectTo)); // leaving the page, loosing state
            firebase.auth().signInWithRedirect(provider);
        }

        return isWaitingRedirect ? 
            <InfoBox title="Continua con" className="Login"><div className="loader">Caricando..</div></InfoBox> : 

            <InfoBox title="Continua con" className="LoginSocial">    
                <Button type="button"leftIcon={faGoogle} style={{width: "100%"}} onClick={() => {
                    signInWithRedirect(new firebase.auth.GoogleAuthProvider());
                }}>Google</Button><br/>
                <Button type="button"leftIcon={faFacebookF} style={{width: "100%"}} onClick={() => {
                    signInWithRedirect(new firebase.auth.FacebookAuthProvider());
                }}>Facebook</Button>
                <Button type="button"leftIcon={faEnvelope} style={{width: "100%"}} onClick={() => {
                    history.push('/login/email', { pendingRedirect: onSuccessRedirectTo});
                }}>Email</Button>
                <p style={{fontSize: '0.7em', lineHeight: "0.9em", margin: "1em 0 2em 0"}}>Continuando, accetti <a href="https://www.iubenda.com/termini-e-condizioni/63470479" title="Termini e Condizioni" target="_BLANK" rel="noreferrer"> Termini e Condizioni</a> e  
                    <a href="https://www.iubenda.com/privacy-policy/63470479" title="Privacy Policy" target="_BLANK" rel="noreferrer"> Privacy Policy</a> 
                </p>
               
                </InfoBox>
    }
}

export default Login