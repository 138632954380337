import { FormControl, InputLabel, Select as MatSelect } from "@material-ui/core"
import React, { CSSProperties } from "react"

const Select = (props: {
    label: string,
    children: React.ReactNode ,
    onChange?: (newvalue: string) => void,
    style?: CSSProperties,
    className?: string,
    error?: boolean,
}) => {
    const [value, setValue] = React.useState('');
    
    return <div className={"MySelect"+((" "+props.className) ?? '')+(props.error ? ' error' : '')} style={props.style}>
        <FormControl variant="filled" style={{width: "100%"}}>
            <InputLabel id={props.label}>{props.label}</InputLabel>
            <MatSelect
                labelId={props.label} 
                value={value}
                onChange={(e, c) => {
                    const newvalue = e.target.value as string;
                    props.onChange?.(newvalue);
                    setValue(newvalue);
                    }
                }>
                {props.children}
            </MatSelect>
        </FormControl>
    </div>
}

export default Select