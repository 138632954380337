import { faStar as faStarFull, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import './style.scss';

const StarsComp = (props: {
        stars: number, 
        starStyle?: React.CSSProperties,
        onSelect?: (stars: number) => void
    }) => {
    const [selectedStars, setSelectedStars] = useState(props.stars)
    const [highlightedStars, setHighlightedStars] = useState<number | undefined>(undefined)
    
    const onSelect = (n: number) => {
        if (props.onSelect !== undefined){
            setSelectedStars(n);
            props.onSelect?.(n);
        }
    }

    const initialStars = props.onSelect !== undefined && highlightedStars !== undefined ? highlightedStars : selectedStars;
    let s = initialStars;
    let elements = [];
    let i = 1;
    while(s >= 1){
        const ii = i;
        elements.push(<FontAwesomeIcon icon={faStarFull} key={`full${s}`} 
            onMouseEnter={()=>{setHighlightedStars(ii)}} 
            onMouseLeave={()=>{setHighlightedStars(undefined)}} 
            onClick={()=>onSelect(ii)}
            style={props.starStyle}
            />)
        i += 1;
        s -= 1;
    }
    if (s === 0.5) {
        const ii = i;
        elements.push(<FontAwesomeIcon icon={faStarHalfAlt} key='half' 
            onMouseEnter={()=>{setHighlightedStars(ii)}} 
            onMouseLeave={()=>{setHighlightedStars(undefined)}}
            onClick={()=>onSelect(ii)}
            style={props.starStyle}
        />)
        i += 1;
    }
    let empty = 5 - initialStars;
    while(empty >= 1){
        const ii = i;
        elements.push(<FontAwesomeIcon icon={faStarEmpty} key={`empty${empty}`}
            onMouseEnter={()=>{setHighlightedStars(ii)}} 
            onMouseLeave={()=>{setHighlightedStars(undefined)}}
            onClick={()=>onSelect(ii)}
            style={props.starStyle}
        />)
        i += 1;
        empty -= 1;
    }
    return <span className={`StarsComp${props.onSelect !== undefined ? " selectable" : ""}`} style={{textAlign: 'right', whiteSpace: "nowrap"}}>{elements}</span>
}

export default StarsComp;