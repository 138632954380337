import { faChevronRight, faCity, faHouseUser, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import { FirebaseRequest } from '../../../../shared/data-model/requests';
import { track } from '../../utils/data/tracking';
import { uploadRequest } from '../../utils/firebase';
import { useUser } from '../../utils/use-user';
import Button from '../Button';
import {  smartForm } from '../Form';
import TwoColumnsWrappedContent from '../layout/TwoColumnsWrappedContent';
import MessageBox from '../MessageBox';
import './style.scss';
import { UserAsyncRequest, UserAsyncRequestContext } from './UserAsyncRequest';

type Data = {
    nome: string
    indirizzo: string
    citta: string
    provincia: string
};

const NewAg = () => {

    const { user } = useUser();

    return <div className='Main main vertical-fill NewAg'>
    <TwoColumnsWrappedContent
        main={<UserAsyncRequest
        notSubmitted={
                    <UserAsyncRequestContext.Consumer>
                        {ctx => {
                          
                            const form = smartForm((data: Data) => {
                                track("NEWAG_SUBMIT");
                                
                                const rq: FirebaseRequest = {
                                    type: 'new-agenzia',
                                    agenzia: data,
                                }
                                ctx.changeState("submitting");
                                uploadRequest(`${user?.id ?? "undefined_user"}_${DateTime.now().toSeconds()}`, rq).then(() => {
                                    track("NEW_REVIEW_SUCCESS");
                                    ctx.changeState("success");
                                }).catch((e)=> {
                                    console.error(e);
                                    ctx.changeState("error");
                                })
                            });
                            return <form.Form>
                                <form.Input placeholder="Nome Agenzia" name="nome" icon={faHouseUser}
                                    validator={
                                        (v) => v.length === 0 ? "Scrivi un nome" : undefined
                                    } />
                                    
                                <form.Input placeholder="Indirizzo" name="indirizzo" icon={faMapMarkerAlt}
                                    validator={
                                        (v) => v.length < 5 ? "Scrivi un indirizzo valido" : undefined
                                    } />
                                    
                                <div style={{ display: "flex" }}>
                                        
                                    <form.Input placeholder="Città" name="citta" className="citta" icon={faCity} style={{ flexGrow: 1 }}
                                        validator={
                                            (v) => v.length < 2 ? "Scrivi una città valida" : undefined
                                        } />
                                
                                    <form.Input placeholder="Provincia" name="provincia" className="provincia" style={{ flexGrow: 1 }}
                                        validator={
                                            (v) => v.length < 2 ? "Scrivi una provincia valida" : undefined
                                        } />
                                            
                                </div>
                            
                                <div style={{ display: "flex" }}>
                                    <div style={{ flexGrow: 1 }} />
                                    <Button rightIcon={faChevronRight} type="submit">Aggiungi</Button>
                                </div>
                            </form.Form>
                            }
                        }
            </UserAsyncRequestContext.Consumer>
                    } 
        submitting={<MessageBox>Stiamo caricando la nuova agenzia...</MessageBox>}
        success={<MessageBox>La nuova agenzia è stata caricata, una volta verificata la vedrai online, grazie!</MessageBox>}
        error={ <MessageBox>Ci dispiace, qualcosa è andato storto. Riprova più tardi o contatta l'assistenza.</MessageBox>}
            />}
            />
            </div>
        }

export default NewAg