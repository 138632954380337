import { useHistory } from 'react-router-dom';
import { AgenziaSuggestion } from '../../utils/data';
import ContentWrapper from '../layout/ContentWrapper';
import SearchInputs from '../SearchInputs';
import './style.scss';

const SelectForNewReview = () => {
    const history = useHistory();

    return <div className='Main main vertical-fill'>
        <ContentWrapper>
        <h3>Seleziona Agenzia Immobiliare</h3>
            <div style={{ position: "relative", width: "100%" }}>
                <p />
                <div ><SearchInputs
                    onSelect={(agenzia: AgenziaSuggestion) => {
                        history.push(`/nuova-recensione/${agenzia.sigla}/${agenzia.id}`)
                    }}
                /></div>
            </div>
        </ContentWrapper>
    </div>
}

export default SelectForNewReview