import { createContext, useState } from "react";

type State = "notSubmitted" | "submitting" | "error" | "success";

interface Context {
    changeState: (state: State) => void;
}

export const UserAsyncRequestContext = createContext<Context>(undefined!);


export const UserAsyncRequest = (props: {
    [state in State]: React.ReactNode
}) => {
    const [requestState, setRequestState] = useState<State>("notSubmitted");

    return <UserAsyncRequestContext.Provider value={{ changeState: setRequestState }}>
        {props[requestState]}
    </UserAsyncRequestContext.Provider>

};
