// https://usehooks.com/useAuth/ 

import React, { useState, useEffect, useContext, createContext, ReactNode } from "react";

import firebase from "firebase";

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    

const [user, setUser] = useState(firebase.auth().currentUser ?? undefined);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (email: string, password: string) => {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password);
    if (response.user !== null) {
      setUser(response.user);
      return user;
    }

  };

  const signup = async (email: string, password: string, displayName: string): Promise<firebase.User | undefined> => {
    const response = await firebase.auth().createUserWithEmailAndPassword(email, password);
      
    if(response.user !== null){
      setUser(response.user);
      return response.user;
    }
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(undefined);
      });
  };

  const sendPasswordResetEmail = (email: string) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code:string , password: string) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const isUserVerified = () => {
    return user !== undefined &&
      (user.emailVerified
        || user.providerData.find(d => d && d.providerId === 'facebook.com')   // https://github.com/firebase/firebase-js-sdk/issues/340))
      );
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async newUser => {
      if (newUser !== null) {
        setUser(
         newUser,
        );
      } else {
        setUser(undefined);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  },
   [
    //  Otherwise render loop between use-auth and use-user happen. 
    //  userInfo
    ]
   );

  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    isUserVerified,
  };
}

const authContext = createContext<ReturnType<typeof useProvideAuth>>(undefined!); // https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth(props: { children: ReactNode }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{props.children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
