import _ from "lodash";
import { createContext, ReactNode, useContext, useState } from "react";

export type PageInfo = { 
    idArea?: string,
    idAgenzia?: string,
};

function _usePageInfo() {
    const [pageInfo, setPageInfo] = useState<PageInfo>();
    return {
        pageInfo,
        setPageInfo: (newPageInfo: PageInfo) => {
            if (!_.isEqual(pageInfo, newPageInfo)) {
                setPageInfo(newPageInfo);    
            }
        },
    };
}

const pageInfoContext = createContext<ReturnType<typeof _usePageInfo>>(undefined!);  // https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947

export function ProvidePageInfo(props: { children: ReactNode }) {
    const pageInfo = _usePageInfo();
    return <pageInfoContext.Provider value={pageInfo}>{props.children}</pageInfoContext.Provider>;
  }  

  export const usePageInfo = () => {
    return useContext(pageInfoContext);
  };